// src/components/Footer.js
import './Footer.css';
import React from 'react';

const Footer = () => {
  return (
    <footer className="text-center mt-auto">
      <p>Ahmad Umar &copy; 2024</p>
    </footer>
  );
};

export default Footer;
